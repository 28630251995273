import './index.css'; // Importing global styles first
import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated to use the new createRoot API
import App from './App';
import 'react-datepicker/dist/react-datepicker.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
