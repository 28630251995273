import React, { useState, useRef, useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faUser } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import debounce from 'lodash.debounce';
import './DenunciadoSelect.css'; // Ensure the CSS file is imported

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#142723',
    color: '#93c7ab',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#142723',
    color: '#93c7ab',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#93c7ab',
  }),
  input: (provided) => ({
    ...provided,
    color: '#93c7ab',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#93c7ab',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#93c7ab' : '#142723',
    color: state.isFocused ? '#142723' : '#93c7ab',
  }),
};

const DenunciadoSelect = ({ formik, newDenunciado, setNewDenunciado }) => {
  const [inputValue, setInputValue] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchDenunciados = async (inputValue) => {
    if (inputValue.length < 4) return [];
    setLoading(true);
    try {
      const response = await axios.get(`http://localhost:8714/api/denunciados?search=${inputValue}`);
      return response.data.map((denunciado) => ({
        value: denunciado._id,
        label: denunciado.nombre,
      }));
    } catch (error) {
      console.error('Error fetching denunciados:', error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchDenunciados = useCallback(debounce(fetchDenunciados, 300), []);

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
    setNewDenunciado(inputValue);
    setShowConfirmation(false); // Reset confirmation message when input changes
    setMenuOpen(inputValue.length >= 4); // Open menu if input length is sufficient
    if (inputValue.length >= 4) {
      debouncedFetchDenunciados(inputValue);
    }
  };

  const handleSelectChange = (option) => {
    if (option?.value === 'new') {
      formik.setFieldValue('denunciado', { value: 'new', label: inputValue });
    } else {
      formik.setFieldValue('denunciado', option);
      setNewDenunciado(null);
    }
  };

  const handleCreateOption = () => {
    formik.setFieldValue('denunciado', { value: 'new', label: inputValue });
    setShowConfirmation(true); // Show confirmation message
    setMenuOpen(false); // Close menu
    setTimeout(() => {
      setShowConfirmation(false);
      setInputValue(''); // Clear input after confirmation
    }, 2000); // Hide after 2 seconds
  };

  const formatCreateLabel = (inputValue) => (
    <div onClick={handleCreateOption} style={{ cursor: 'pointer' }}>
      <FontAwesomeIcon icon={faPlusCircle} /> Agregar Nuevo Denunciado: "{inputValue}"
    </div>
  );

  const noOptionsMessage = () => (inputValue.length >= 4 ? formatCreateLabel(inputValue) : 'Ingrese al menos 4 caracteres para buscar');

  return (
    <div className="form-group">
      <label><FontAwesomeIcon icon={faUser} /> Denunciado*:</label>
      <AsyncSelect
        cacheOptions
        defaultOptions={false}
        loadOptions={fetchDenunciados}
        onInputChange={handleInputChange}
        onChange={handleSelectChange}
        placeholder="Buscar o crear denunciado..."
        noOptionsMessage={noOptionsMessage}
        isClearable
        value={formik.values.denunciado}
        styles={customStyles}
        menuIsOpen={menuOpen && !showConfirmation}
        isLoading={loading}
      />
      {showConfirmation && (
        <div className="confirmation-message">
          Nuevo denunciado "{inputValue}" agregado.
        </div>
      )}
      {formik.touched.denunciado && formik.errors.denunciado ? (
        <div className="error">{formik.errors.denunciado}</div>
      ) : null}
    </div>
  );
};

export default DenunciadoSelect;
