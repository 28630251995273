import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import Modal from 'react-modal';

// Ensure the default marker icon images are set correctly
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

Modal.setAppElement('#root'); // Set this to the id of your root element

// Setting default icon options manually
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const MapModal = ({ isOpen, onRequestClose, onLocationSelect, initialCenter }) => {
  const mapRef = useRef();
  const [markerPosition, setMarkerPosition] = useState(initialCenter);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setView([initialCenter.lat, initialCenter.lng], 13);
      setMarkerPosition(initialCenter);
    }
  }, [initialCenter]);

  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        setMarkerPosition(e.latlng);
        onLocationSelect({ lat, lng });
      },
    });

    return markerPosition ? (
      <Marker position={markerPosition}></Marker>
    ) : null;
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onRequestClose={onRequestClose} 
      className="map-modal" 
      overlayClassName="map-modal-overlay"
    >
      <MapContainer
        center={[initialCenter.lat, initialCenter.lng]}
        zoom={13}
        style={{ height: '400px', width: '100%' }}
        whenCreated={(mapInstance) => { mapRef.current = mapInstance }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <LocationMarker />
      </MapContainer>
    </Modal>
  );
};

export default MapModal;
