import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import Button from './Button';
import Subtitle from './Subtitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faLocationArrow, faTag, faLink, faAlignLeft, faTrash, faPlusCircle, faUser, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import UbigeoDropdowns from './UbigeoDropdowns';
import DenunciadoSelect from './DenunciadoSelect';
import MapModal from './MapModal'; // Import the MapModal component
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import './DenunciaForm.css'; // Ensure the CSS file is imported

const validationSchema = Yup.object({
  tipo: Yup.object().required('Tipo es requerido'),
  fecha_ocurrencia: Yup.date().required('Fecha y Hora Ocurrencia son requeridos').nullable(),
  departamento_ocurrencia: Yup.string().required('Departamento es requerido'),
  provincia_ocurrencia: Yup.string().required('Provincia es requerida'),
  distrito_ocurrencia: Yup.string().required('Distrito es requerido'),
  denunciado: Yup.object().required('Denunciado es requerido'),
  rango: Yup.object().required('Rango es requerido'),
  descripcion: Yup.string(),
  url_evidencia: Yup.array().of(Yup.string().url('Debe ser una URL válida')),
});

const tipoOptions = [
  { value: 'Corrupción', label: 'Corrupción' },
  { value: 'Mala conducta', label: 'Mala conducta' },
  { value: 'Abuso de autoridad', label: 'Abuso de autoridad' },
  { value: 'Extorsión', label: 'Extorsión' },
  { value: 'Negligencia', label: 'Negligencia' },
  { value: 'Discriminación', label: 'Discriminación' },
  { value: 'Violencia', label: 'Violencia' },
  { value: 'Obstrucción a la justicia / Encubrimiento', label: 'Obstrucción a la justicia / Encubrimiento' },
];

const rangoOptions = [
  'General',
  'Teniente General',
  'Coronel',
  'Comandante',
  'Mayor',
  'Capitán',
  'Teniente',
  'Alférez',
  'Suboficial Superior',
  'Suboficial Brigadier',
  'Suboficial Técnico',
  'Suboficial Técnico de Primera',
  'Suboficial Técnico de Segunda',
  'Suboficial Técnico de Tercera',
  'Suboficial',
  'Suboficial de Primera',
  'Suboficial de Segunda',
  'Suboficial de Tercera',
  'Especialista',
  'Especialista Superior',
  'Especialista Brigadier',
  'Especialista Técnico de Primera',
  'Especialista Técnico de Segunda',
  'Especialista Técnico de Tercera',
  'Especialista de Primera',
  'Especialista de Segunda',
  'Especialista de Tercera'
].map(rango => ({ value: rango, label: rango })).sort((a, b) => a.label.localeCompare(b.label));

const DenunciaForm = () => {
  const [links, setLinks] = useState(['']);
  const [newDenunciado, setNewDenunciado] = useState(null);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationCenter, setLocationCenter] = useState({ lat: -9.189967, lng: -75.015152 }); // Default to the center of Peru

  const formik = useFormik({
    initialValues: {
      tipo: null,
      fecha_ocurrencia: null,
      departamento_ocurrencia: '',
      provincia_ocurrencia: '',
      distrito_ocurrencia: '',
      denunciado: null,
      rango: null,
      descripcion: '',
      url_evidencia: [''],
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const formValues = {
          ...values,
          id_usuario: 'id_del_usuario_autenticado', // Deberías obtener el ID del usuario autenticado
          denunciado: values.denunciado ? values.denunciado.value : null,
          ubicacion_lat: selectedLocation ? selectedLocation.lat : null,
          ubicacion_lng: selectedLocation ? selectedLocation.lng : null,
        };

        if (values.denunciado.value === 'new') {
          // Create new Denunciado
          const response = await axios.post('http://localhost:8714/api/denunciados', { nombre: newDenunciado });
          formValues.denunciado = response.data._id;
        }

        const response = await axios.post('http://localhost:8714/api/denuncia', formValues);
        if (response.status === 201) {
          alert('Denuncia enviada con éxito!');
        } else {
          alert('Error al enviar la denuncia.');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('Error al enviar la denuncia.');
      }
    },
  });

  const handleLinkChange = (index, value) => {
    const newLinks = links.map((link, i) => (i === index ? value : link));
    setLinks(newLinks);
    formik.setFieldValue('url_evidencia', newLinks);
  };

  const handleRemoveLink = (index) => {
    const newLinks = links.filter((_, i) => i !== index);
    setLinks(newLinks);
    formik.setFieldValue('url_evidencia', newLinks);
  };

  const handleAddLink = () => {
    setLinks([...links, '']);
    formik.setFieldValue('url_evidencia', [...links, '']);
  };

  const handleUbigeoChange = (departamentoName, provinciaName, distritoName) => {
    formik.setFieldValue('departamento_ocurrencia', departamentoName);
    formik.setFieldValue('provincia_ocurrencia', provinciaName);
    formik.setFieldValue('distrito_ocurrencia', distritoName);
  
    if (departamentoName && provinciaName && distritoName) {
      getCoordinatesForUbigeo(departamentoName, provinciaName, distritoName);
    }
  };
  

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
    console.log('Selected location:', location);
  };
  

  const getCoordinatesForUbigeo = async (departamento, provincia, distrito) => {
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/search?format=json&country=Peru&state=${encodeURIComponent(departamento)}&county=${encodeURIComponent(provincia)}&city=${encodeURIComponent(distrito)}&limit=1`);
      const data = response.data;
      if (data.length > 0) {
        const coordinates = { lat: parseFloat(data[0].lat), lng: parseFloat(data[0].lon) };
        setLocationCenter(coordinates);
      } else {
        console.warn('No coordinates found for the given location.');
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };
  

  return (
    <div className="form-container">
      <Subtitle />
      <h2>Enviar Denuncia</h2>
      <form onSubmit={formik.handleSubmit} className="form-grid">
        <div className="form-group full-width">
          <label><FontAwesomeIcon icon={faTag} /> Tipo*:</label>
          <div className="tipo-tags flex flex-wrap gap-2">
            {tipoOptions.map((option) => (
              <div
                key={option.value}
                className={`tag p-2 rounded cursor-pointer 
                  ${formik.values.tipo?.value === option.value ? 'selected' : ''} 
                  hover:bg-[#bfa343] hover:text-[#142723]`}
                onClick={() => formik.setFieldValue('tipo', option)}
              >
                {option.label}
              </div>
            ))}
          </div>
          {formik.touched.tipo && formik.errors.tipo ? (
            <div className="error text-[#c46243]">{formik.errors.tipo}</div>
          ) : null}
        </div>
        <div className="form-group half-width">
          <label><FontAwesomeIcon icon={faCalendarAlt} /> Fecha y Hora Ocurrencia*:</label>
          <div className="relative">
            <DatePicker
              selected={formik.values.fecha_ocurrencia}
              onChange={(date) => formik.setFieldValue('fecha_ocurrencia', date)}
              dateFormat="dd/MM/yyyy HH:mm"
              placeholderText="DD/MM/YYYY HH:mm"
              className="form-select w-full p-2 border rounded bg-[#142723] text-[#93c7ab]"
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="Hora"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              customInput={<CustomDateInput />}
            />
            <FontAwesomeIcon icon={faCalendarAlt} className="absolute right-2 top-2 text-xl text-[#93c7ab]" />
          </div>
          {formik.touched.fecha_ocurrencia && formik.errors.fecha_ocurrencia ? (
            <div className="error text-[#c46243]">{formik.errors.fecha_ocurrencia}</div>
          ) : null}
        </div>
        <div className="form-group half-width flex-container">
          <div className="dropdown-container">
            <label><FontAwesomeIcon icon={faLocationArrow} /> Ubicación de Ocurrencia*:</label>
            <UbigeoDropdowns onChange={handleUbigeoChange} />
            {formik.touched.departamento_ocurrencia && formik.errors.departamento_ocurrencia ? (
              <div className="error text-[#c46243]">{formik.errors.departamento_ocurrencia}</div>
            ) : null}
            {formik.touched.provincia_ocurrencia && formik.errors.provincia_ocurrencia ? (
              <div className="error text-[#c46243]">{formik.errors.provincia_ocurrencia}</div>
            ) : null}
            {formik.touched.distrito_ocurrencia && formik.errors.distrito_ocurrencia ? (
              <div className="error text-[#c46243]">{formik.errors.distrito_ocurrencia}</div>
            ) : null}
          </div>
          <div className="map-button-container">
            <button type="button" onClick={() => setIsMapModalOpen(true)} className="map-button">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
              <span className="text">Ubicar en mapa</span>
            </button>
          </div>
        </div>
        <div className="form-group half-width">
          <DenunciadoSelect formik={formik} newDenunciado={newDenunciado} setNewDenunciado={setNewDenunciado} />
        </div>
        <div className="form-group half-width">
          <label>Rango*:</label>
          <select
            name="rango"
            value={formik.values.rango?.value || ''}
            onChange={(e) => {
              const selectedOption = rangoOptions.find(option => option.value === e.target.value);
              formik.setFieldValue('rango', selectedOption);
            }}
            onBlur={formik.handleBlur}
            className="form-select w-full p-2 border rounded bg-[#142723] text-[#93c7ab]"
          >
            <option value="" label="Seleccionar rango..." />
            {rangoOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {formik.touched.rango && formik.errors.rango ? (
            <div className="error text-[#c46243]">{formik.errors.rango}</div>
          ) : null}
        </div>
        <div className="form-group full-width">
          <label><FontAwesomeIcon icon={faAlignLeft} /> Descripción:</label>
          <textarea
            name="descripcion"
            value={formik.values.descripcion}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full p-2 border rounded bg-[#142723] text-[#93c7ab]"
          />
          {formik.touched.descripcion && formik.errors.descripcion ? (
            <div className="error text-[#c46243]">{formik.errors.descripcion}</div>
          ) : null}
        </div>
        <div className="form-group full-width">
          <label><FontAwesomeIcon icon={faLink} /> Links de Evidencia:</label>
          {links.map((link, index) => (
            <div key={index} className="link-input-group mb-2">
              <input
                type="url"
                value={link}
                onChange={(e) => handleLinkChange(index, e.target.value)}
                placeholder="Ingrese un enlace"
                className="link-input p-2 border rounded bg-[#142723] text-[#93c7ab]"
              />
              {index > 0 && (
                <button type="button" className="remove-link-button p-2 text-[#c46243]" onClick={() => handleRemoveLink(index)}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              )}
            </div>
          ))}
          <button type="button" className="add-link-button flex items-center p-2 rounded mt-2" onClick={handleAddLink}>
            <FontAwesomeIcon icon={faPlusCircle} />&nbsp; Añadir otro enlace
          </button>
        </div>
        <div className="form-group full-width">
          <Button type="submit">Enviar Denuncia</Button>
        </div>
      </form>
      <MapModal
  isOpen={isMapModalOpen}
  onRequestClose={() => setIsMapModalOpen(false)}
  onLocationSelect={handleLocationSelect}
  initialCenter={locationCenter}
/>
    </div>
  );
};

const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
  <div className="custom-date-input p-2 border rounded bg-[#142723] text-[#93c7ab] cursor-pointer" onClick={onClick} ref={ref}>
    {value || 'Selecciona una fecha'}
  </div>
));

export default DenunciaForm;
