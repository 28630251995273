// src/components/RouteGuard.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import config from '../config';

const RouteGuard = ({ children }) => {
  const isAuthenticated = false; // Replace with your actual authentication logic

  if (!config.isProduction) {
    return children; // Allow access to all routes in non-production environments
  }

  return isAuthenticated ? children : <Navigate to="/" />;
};

export default RouteGuard;
