import React, { useState, useEffect } from 'react';

const UbigeoDropdowns = ({ onChange }) => {
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState('');
  const [selectedProvincia, setSelectedProvincia] = useState('');
  const [selectedDistrito, setSelectedDistrito] = useState('');
  const [provincias, setProvincias] = useState([]);
  const [distritos, setDistritos] = useState([]);

  useEffect(() => {
    const fetchDepartamentos = async () => {
      try {
        const response = await fetch('http://localhost:8714/departamentos');
        const data = await response.json();
        setDepartamentos(data);
      } catch (error) {
        console.error('Error fetching departamentos:', error);
      }
    };

    fetchDepartamentos();
  }, []);

  useEffect(() => {
    if (selectedDepartamento) {
      const fetchProvincias = async () => {
        try {
          const response = await fetch(`http://localhost:8714/provincias/${selectedDepartamento}`);
          const data = await response.json();
          setProvincias(data);
        } catch (error) {
          console.error('Error fetching provincias:', error);
        }
      };

      fetchProvincias();
      setSelectedProvincia('');
      setSelectedDistrito('');
      setDistritos([]);
    } else {
      setProvincias([]);
      setDistritos([]);
    }
  }, [selectedDepartamento]);

  useEffect(() => {
    if (selectedProvincia) {
      const fetchDistritos = async () => {
        try {
          const response = await fetch(`http://localhost:8714/distritos/${selectedProvincia}`);
          const data = await response.json();
          setDistritos(data);
        } catch (error) {
          console.error('Error fetching distritos:', error);
        }
      };

      fetchDistritos();
      setSelectedDistrito('');
    } else {
      setDistritos([]);
    }
  }, [selectedProvincia]);

  useEffect(() => {
    const departamento = departamentos.find(dep => dep.id_ubigeo === selectedDepartamento)?.nombre_ubigeo || '';
    const provincia = provincias.find(prov => prov.id_ubigeo === selectedProvincia)?.nombre_ubigeo || '';
    const distrito = distritos.find(dist => dist.id_ubigeo === selectedDistrito)?.nombre_ubigeo || '';

    onChange(departamento, provincia, distrito);
  }, [selectedDepartamento, selectedProvincia, selectedDistrito]);

  return (
    <div className="ubigeo-dropdowns">
      <div className="form-group">
        <select
          value={selectedDepartamento}
          onChange={e => setSelectedDepartamento(e.target.value)}
          className="form-select"
        >
          <option value="">Seleccione Departamento</option>
          {departamentos.map(departamento => (
            <option key={departamento.id_ubigeo} value={departamento.id_ubigeo}>
              {departamento.nombre_ubigeo}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <select
          value={selectedProvincia}
          onChange={e => setSelectedProvincia(e.target.value)}
          className="form-select"
          disabled={!selectedDepartamento}
        >
          <option value="">Seleccione Provincia</option>
          {provincias.map(provincia => (
            <option key={provincia.id_ubigeo} value={provincia.id_ubigeo}>
              {provincia.nombre_ubigeo}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <select
          value={selectedDistrito}
          onChange={e => setSelectedDistrito(e.target.value)}
          className="form-select"
          disabled={!selectedProvincia}
        >
          <option value="">Seleccione Distrito</option>
          {distritos.map(distrito => (
            <option key={distrito.id_ubigeo} value={distrito.id_ubigeo}>
              {distrito.nombre_ubigeo}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default UbigeoDropdowns;
