import React from 'react';

const Dashboard = () => {
  return (
    <div>
      <h2>Welcome to your Dashboard</h2>
      <p>This is a protected route. You can see this because you are logged in.</p>
    </div>
  );
};

export default Dashboard;
