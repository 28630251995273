// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lightbox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .lightbox-content {
    background: #1e2a26;
    padding: 20px;
    border-radius: 8px;
    color: #93c7ab;
    max-width: 600px;
    width: 90%;
    position: relative;
  }
  
  .lightbox-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: #93c7ab;
    cursor: pointer;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Lightbox.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;EACf;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,cAAc;IACd,gBAAgB;IAChB,UAAU;IACV,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,cAAc;IACd,eAAe;EACjB","sourcesContent":[".lightbox-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.8);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1000;\n  }\n  \n  .lightbox-content {\n    background: #1e2a26;\n    padding: 20px;\n    border-radius: 8px;\n    color: #93c7ab;\n    max-width: 600px;\n    width: 90%;\n    position: relative;\n  }\n  \n  .lightbox-close {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    background: none;\n    border: none;\n    font-size: 24px;\n    color: #93c7ab;\n    cursor: pointer;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
