import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Button from './Button';
import Subtitle from './Subtitle';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate(); // Use navigate for redirection

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:8714/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      const data = await response.json();
      if (response.ok) {
        setMessage('Código de verificación enviado a tu correo.');
        // Redirect to the verification page and pass the email as state
        navigate('/verify-login', { state: { email } });
      } else {
        setMessage(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Error al enviar el código de verificación.');
    }
  };

  return (
    <div className="form-container">
      <Subtitle />
      <h2>Iniciar Sesión</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Correo Electrónico:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <Button type="submit">Enviar Código</Button>
        </div>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default LoginForm;
