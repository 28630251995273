import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SplashPage from './components/SplashPage';
import UserRegistrationForm from './components/UserRegistrationForm';
import LoginForm from './components/LoginForm';
import VerificationForm from './components/VerificationForm';
import Dashboard from './components/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import DenunciaForm from './components/DenunciaForm';
import RouteGuard from './components/RouteGuard';
import config from './config';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<SplashPage />} />
          <Route 
            path="/registro" 
            element={
              <RouteGuard>
                <UserRegistrationForm />
              </RouteGuard>
            } 
          />
          <Route 
            path="/login" 
            element={
              <RouteGuard>
                <LoginForm />
              </RouteGuard>
            } 
          />
          <Route 
            path="/verify-login" 
            element={
              <RouteGuard>
                <VerificationForm />
              </RouteGuard>
            } 
          />
          <Route 
            path="/dashboard" 
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/denuncia" 
            element={
              <RouteGuard>
                <DenunciaForm />
              </RouteGuard>
            } 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
