import React from 'react';
import './Subtitle.css';

const Subtitle = () => {
  return (
    <h3 className="subtitle">
      <span className="text-honor">Honor</span>
      <span className="text-and">y</span>
      <span className="text-coima">Coima</span>
      <span className="text-org">.org</span>
    </h3>
  );
};

export default Subtitle;
