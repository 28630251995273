import React from 'react';

const Button = ({ children, ...props }) => {
  return (
    <button {...props} className="bg-[#c46243] text-white py-2 px-4 rounded hover:bg-[#a33c32]">
      {children}
    </button>
  );
};

export default Button;
