import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from './Button'; // Asegúrate de ajustar la ruta según sea necesario

const VerificationForm = () => {
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email || ''; // Obtener el correo electrónico de la ubicación

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:8714/api/verify-login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, code })
      });

      const data = await response.json();
      if (response.ok) {
        setMessage('¡Login exitoso!');
        // Redirigir a la página principal o dashboard
        navigate('/dashboard'); // Suponiendo que tienes una página de dashboard
      } else {
        setMessage(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Falló la verificación.');
    }
  };

  return (
    <div className="form-container">
      <h2>Verificar Código</h2>
      <p style={{ color: '#142723', backgroundColor: '#93c7ab', padding: '15px', borderRadius: '5px', marginBottom: '20px' }}>
        Te enviamos un correo electrónico con tu código de acceso.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Código:</label>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <Button type="submit">Verificar</Button>
        </div>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default VerificationForm;
