// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subtitle {
    font-family: 'PT Mono', monospace; /* Use PT Mono font */
    font-size: 1.5rem; /* Smaller font size for subtitle */
    text-align: center; /* Center align */
    margin-bottom: 20px; /* Margin below subtitle */
  }
  
  .text-honor {
    color: #bfa343; /* Color for Honor */
    text-decoration: underline;
  }
  
  .text-and,
  .text-org {
    color: #93c7ab; /* Color for y and .org */
  }
  
  .text-coima {
    color: #c46243; /* Color for Coima */
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Subtitle.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC,EAAE,qBAAqB;IACxD,iBAAiB,EAAE,mCAAmC;IACtD,kBAAkB,EAAE,iBAAiB;IACrC,mBAAmB,EAAE,0BAA0B;EACjD;;EAEA;IACE,cAAc,EAAE,oBAAoB;IACpC,0BAA0B;EAC5B;;EAEA;;IAEE,cAAc,EAAE,yBAAyB;EAC3C;;EAEA;IACE,cAAc,EAAE,oBAAoB;IACpC,0BAA0B;EAC5B","sourcesContent":[".subtitle {\n    font-family: 'PT Mono', monospace; /* Use PT Mono font */\n    font-size: 1.5rem; /* Smaller font size for subtitle */\n    text-align: center; /* Center align */\n    margin-bottom: 20px; /* Margin below subtitle */\n  }\n  \n  .text-honor {\n    color: #bfa343; /* Color for Honor */\n    text-decoration: underline;\n  }\n  \n  .text-and,\n  .text-org {\n    color: #93c7ab; /* Color for y and .org */\n  }\n  \n  .text-coima {\n    color: #c46243; /* Color for Coima */\n    text-decoration: underline;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
