import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import UbigeoDropdowns from './UbigeoDropdowns';
import Button from './Button';
import Subtitle from './Subtitle';
import Lightbox from './Lightbox';
import { subYears } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const validationSchema = Yup.object({
  apodo: Yup.string().required('Apodo es requerido'),
  email: Yup.string().email('Correo inválido').required('Correo es requerido'),
  fecha_nacimiento: Yup.date().required('Fecha de nacimiento es requerida').nullable(),
  como_enteraste: Yup.string().required('Campo requerido'),
});

const UserRegistrationForm = () => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [apodoAvailable, setApodoAvailable] = useState(true);
  const [suggestedApodos, setSuggestedApodos] = useState([]);
  const [lastSelectedApodo, setLastSelectedApodo] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Agregar estado para el mensaje de error
  const navigate = useNavigate(); // Utilizar useNavigate para redirigir

  const formik = useFormik({
    initialValues: {
      apodo: '',
      email: '',
      fecha_nacimiento: null,
      departamento: '',
      provincia: '',
      distrito: '',
      como_enteraste: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch('http://localhost:8714/api/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        });

        const data = await response.json();
        if (response.ok) {
          navigate('/verify-login', { state: { email: values.email } }); // Redirigir a la página de verificación
        } else {
          setErrorMessage(data.message || 'Error en Registro de Usuario. Verificar que correo electrónico no haya sido registrado previamente.'); // Mostrar mensaje de error
        }
      } catch (error) {
        console.error('Error:', error);
        setErrorMessage('Error en Registro de Usuario. Verificar que correo electrónico no haya sido registrado previamente.');
      }
    },
  });

  useEffect(() => {
    if (formik.values.apodo && formik.values.apodo !== lastSelectedApodo) {
      const checkApodoAvailability = async () => {
        try {
          const response = await fetch(`http://localhost:8714/api/check-apodo?apodo=${formik.values.apodo}`);
          const data = await response.json();
          setApodoAvailable(data.available);

          if (!data.available) {
            const suggestions = Array.from({ length: 3 }, () => `${formik.values.apodo}#${Math.floor(Math.random() * 10000)}`);
            setSuggestedApodos(suggestions);
          } else {
            setSuggestedApodos([]);
          }
        } catch (error) {
          console.error('Error checking apodo availability:', error);
        }
      };

      checkApodoAvailability();
    }
  }, [formik.values.apodo, lastSelectedApodo]);

  return (
    <div className="form-container">
      <Subtitle />
      <h2>Regístrate</h2>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <label>Apodo*:</label>
          <input
            type="text"
            name="apodo"
            value={formik.values.apodo}
            onChange={(e) => {
              formik.handleChange(e);
              setSuggestedApodos([]); // Clear suggestions when typing a new apodo
              setLastSelectedApodo(''); // Reset last selected apodo
              setApodoAvailable(true); // Reset apodoAvailable when typing a new apodo
            }}
            onBlur={formik.handleBlur}
            required
          />
          {formik.touched.apodo && formik.errors.apodo ? (
            <div className="error">{formik.errors.apodo}</div>
          ) : null}
          {!apodoAvailable && suggestedApodos.length === 0 && (
            <div className="error">
              Apodo no disponible.
            </div>
          )}
          {!apodoAvailable && suggestedApodos.length > 0 && (
            <div className="error">
              Apodo no disponible. Sugerencias:
              <ul>
                {suggestedApodos.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      formik.setFieldValue('apodo', suggestion);
                      setSuggestedApodos([]); // Clear suggestions after selection
                      setLastSelectedApodo(suggestion); // Set last selected apodo
                      setApodoAvailable(true); // Set apodoAvailable to true after selection
                    }}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="form-group">
          <label>Correo Electrónico*:</label>
          <input
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="error">{formik.errors.email}</div>
          ) : null}
          <p className="info-text">
            En HonoryCoima.org no solicitamos una contraseña para acceder a tu cuenta. Al iniciar sesión, deberás proveer un código enviado a tu correo electrónico. 
            <FontAwesomeIcon 
              icon={faInfoCircle} 
              className="info-icon" 
              onClick={() => setIsLightboxOpen(true)} 
            />
          </p>
        </div>
        <div className="form-group">
          <label>Fecha de Nacimiento:</label>
          <DatePicker
            selected={formik.values.fecha_nacimiento}
            onChange={(date) => formik.setFieldValue('fecha_nacimiento', date)}
            dateFormat="dd/MM/yyyy"
            placeholderText="DD/MM/YYYY"
            className="form-select"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={subYears(new Date(), 14)}
            minDate={subYears(new Date(), 100)}
          />
          {formik.touched.fecha_nacimiento && formik.errors.fecha_nacimiento ? (
            <div className="error">{formik.errors.fecha_nacimiento}</div>
          ) : null}
        </div>
        <UbigeoDropdowns onChange={(departamento, provincia, distrito) => {
          if (
            departamento !== formik.values.departamento ||
            provincia !== formik.values.provincia ||
            distrito !== formik.values.distrito
          ) {
            formik.setFieldValue('departamento', departamento);
            formik.setFieldValue('provincia', provincia);
            formik.setFieldValue('distrito', distrito);
          }
        }} />
        <div className="form-group">
          <label>¿Cómo te enteraste?:</label>
          <select
            name="como_enteraste"
            value={formik.values.como_enteraste}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="form-select"
            required
          >
            <option value="">Seleccione</option>
            <option value="X (Twitter)">X (Twitter)</option>
            <option value="Facebook">Facebook</option>
            <option value="Instagram">Instagram</option>
            <option value="TikTok">TikTok</option>
            <option value="Prensa Escrita">Prensa Escrita</option>
            <option value="TV">TV</option>
            <option value="Radio">Radio</option>
            <option value="Amigos / Familia">Amigos / Familia</option>
            <option value="Google">Google</option>
          </select>
          {formik.touched.como_enteraste && formik.errors.como_enteraste ? (
            <div className="error">{formik.errors.como_enteraste}</div>
          ) : null}
        </div>
        <div className="form-group">
          <Button type="submit">Registrarse</Button>
        </div>
        {errorMessage && <div className="error">{errorMessage}</div>}
      </form>
      {isLightboxOpen && (
        <Lightbox onClose={() => setIsLightboxOpen(false)}>
          <h2>¿Por qué?</h2>
          <p><strong>Seguridad Mejorada:</strong> Al eliminar las contraseñas, reducimos el riesgo de que sean comprometidas a través de ataques de fuerza bruta o phishing. En su lugar, enviamos un código de verificación único a tu correo electrónico para confirmar tu identidad.</p>
          <p><strong>Comodidad y Simplicidad:</strong> No necesitar recordar una contraseña simplifica el proceso de inicio de sesión. Solo necesitas acceso a tu correo electrónico para ingresar a tu cuenta.</p>
          <p><strong>Protección Continua:</strong> Cada vez que inicias sesión, verificamos que realmente eres el propietario de la cuenta. Esto añade una capa adicional de seguridad, ya que alguien que intente acceder a tu cuenta necesitaría tener acceso a tu correo electrónico.</p>
        </Lightbox>
      )}
    </div>
  );
};

export default UserRegistrationForm;
